.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

td .MuiFormControlLabel-root,
td .MuiFormControl-marginDense {
  margin: 0;
}
td .MuiFormControl-marginDense {
  width: 100%;
}
td.colImgProduct {
  width: 5rem
}
td.colImput {
  width: 15rem;
}


.table td.col-action, .table th.col-action {
  max-width: 8.1rem;
}

.dropdown-menu.show {
  left: auto !important;
  right: 0;
  transform: translate3d(0, 38px, 0px) !important;
}
/* USER
--------------------------------------------------------------------------*/
.userLogado {
  padding: 1rem;
  text-align: left;
}
/* BOTOES
--------------------------------------------------------------------------*/
.MuiButton-containedPrimary,
.MuiFab-primary {
  border-radius: 1.2rem !important;
  background-color: #80AE26 !important;
}
.MuiButton-containedPrimary:hover,
.MuiFab-primary {
  color: #fff;
}
/* NAVBAR
--------------------------------------------------------------------------*/
.navbar {
  background-color: #f6efea;
  box-shadow: 0 0 3px 0px rgba(0,0,0,0.8);
}
/* LOGIN
--------------------------------------------------------------------------*/
#contentLogin {
  bottom: 0px;
  top: 0px;
  right: 0px; 
  left: 0px;
  position: fixed;
  overflow-y: auto;
  background-color: #F6EFEA;
}
.boxLogin {
  width: 25%;
  padding: 1.5rem;
  margin-left: 37.5%;
  margin-top: 12.5%;
  background-color: #fff;
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
  text-align: left;
  border-radius: 0.5rem;
}
.boxLogin .logo {
  text-align: center;
  margin-bottom: 1rem;
}
.boxLogin .title {
  font-size: 1.4rem;
  font-weight: 700;
  color: #80AE26;
}
.boxLogin .form-control {
  margin-bottom: 1rem;
  height: 4rem;
}
.button.login {
  margin-top: 2rem;
}
.button.login, .button.login button {
  background-color: #80AE26;
  border-color: transparent;
  display: block;
  width: 100%;
}
.button.login button {
  line-height: 4rem;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
}
@media screen and (max-width: 1500px) {
  .boxLogin {
    width: 40%;
    margin-left: 30%;
  }
}
@media screen and (max-width: 992px) {
  .boxLogin {
    width: 40%;
    margin-left: 30%;
  }
}
@media screen and (max-width: 768px) {
  .boxLogin {
    width: 50%;
    margin-left: 25%;
  }
  #controlResponse {
    flex-direction: column;
  }
}
@media screen and (max-width: 576px) {
  .boxLogin {
    width: 80%;
    margin-left: 10%;
  }
}
